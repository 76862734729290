import React from "react";
import ResponsivePlot from "components/ResponsivePlot";
import { generateNoisyLineData } from "utils/generate-data";

import styles from "./DVDT.module.less";

const layout = {
  xaxis: {
    title: {
      text: "Elapsed Hours",
    },
  },
  yaxis: {
    title: {
      text: "dV/dt",
    },
    range: [1.72, 1.81],
  },
  margin: {
    t: 5,
    r: 25,
    b: 35,
    l: 50,
  },
};

export default function DVDT({ stacks }) {
  const data = stacks.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Stack-${s.id}`,
      minY: 1.77 + 0.001 * idx,
      maxY: 1.78 + 0.001 * idx,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: `S${idx + 1}`,
      line: {
        width: 1,
      },
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.title}>Local DV/Dt</div>
        <div className={styles.plotContainer}>
          <ResponsivePlot
            data={data}
            layout={layout}
            config={{
              modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
              displaylogo: false,
              responsive: true,
            }}
          />
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.title}>Global DV/Dt</div>
        <div className={styles.content}>
          {stacks.map((s) => (
            <div key={s.id} className={styles.stackContainer}>
              <div className={styles.name}>{s.name}</div>
              <div className={styles.listItem}>
                <div className={styles.val}>{s.dVdT} </div>
                <div className={styles.unit}>dV/dt</div>
              </div>
              <div className={styles.listItem}>
                <div className={styles.val}>{s.eol} </div>
                <div className={styles.unit}>Hrs to MPS</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
