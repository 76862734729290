import React from "react";
import ChartCard from "containers/StackDashboard/components/ChartCard/ChartCard";
import { generateNoisyLineData } from "utils/generate-data";

import styles from "./StackVoltage.module.less";

export default function StackVoltage({ stacks }) {
  const data = stacks.map((s, idx) => {
    const { xValues, yValues } = generateNoisyLineData({
      datetime: true,
      seed: `Stack-${s.id}`,
      minY: 0.4 + 0.022 * idx,
      maxY: 1 + 0.022 * idx,
      numPoints: 30,
    });
    return {
      x: xValues,
      y: yValues,
      type: "scatter",
      mode: "line",
      name: `S${idx + 1}`,
      line: {
        width: 1,
      },
    };
  });
  return (
    <div className={styles.container}>
      <ChartCard data={data} title="Max Voltage" yAxisRange={[0, 2]} />
      <ChartCard data={data} title="Min Voltage" yAxisRange={[0, 2]} />
      <ChartCard data={data} title="Avg Voltage" yAxisRange={[0, 2]} />
      <ChartCard data={data} title="Total Voltage" yAxisRange={[0, 2]} />
      <ChartCard data={data} title="Standard Deviation" yAxisRange={[0, 2]} />
    </div>
  );
}
