import React from "react";

import { generateLogarithmicData } from "utils/generate-data";
import ResponsivePlot from "components/ResponsivePlot";
import styles from "./IVCurve.module.less";

const { xValues, yValues } = generateLogarithmicData();

const dataArray = Array.from({ length: 8 }, (_, index) => ({
  id: index,
  xValues,
  yValues,
}));

const layout = {
  xaxis: {
    title: {
      text: "Current Density (A/cm2)",
      font: {
        size: 13,
      },
    },
  },
  yaxis: {
    title: {
      text: "Voltage (V)",
      font: {
        size: 13,
      },
    },
  },
  margin: {
    t: 5,
    r: 25,
    b: 35,
    l: 50,
  },
};

export default function IVCurve() {
  return (
    <div className={styles.grid}>
      {dataArray.map((item) => (
        <div className={styles.card} key={item.id}>
          <div className={styles.title}>String {item.id + 1}</div>
          <div className={styles.plotContainer}>
            <ResponsivePlot
              data={[{ x: item.xValues, y: item.yValues, type: "scatter", mode: "line" }]}
              layout={layout}
              config={{
                modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
                displaylogo: false,
                responsive: true,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
