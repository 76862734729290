import React from "react";
import ResponsivePlot from "components/ResponsivePlot";
import PlotlyBase from "plotly.js-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";

import styles from "./ChartCard.module.less";

const Plotly = createPlotlyComponent(PlotlyBase);

export default function ChartCard({
  title,
  data,
  xAxisTitle = "",
  yAxisTitle = "",
  axisFontSize = 13,
  xAxisRange,
  yAxisRange,
  margin = { t: 5, r: 25, b: 35, l: 25 },
  height,
  responsive = true,
}) {
  const layout = {
    xaxis: {
      type: "date",
      title: {
        text: { xAxisTitle },
        font: {
          size: { axisFontSize },
        },
      },
      range: xAxisRange,
    },
    yaxis: {
      title: {
        text: yAxisTitle,
        font: {
          size: axisFontSize,
        },
      },
      range: yAxisRange,
    },
    legend: {
      orientation: "h",
      entrywidth: 0,
      y: 20,
      yanchor: "top",
      x: 0.5,
      xanchor: "center",
      font: {
        size: 11,
      },
      indentation: 14,
      itemwidth: 30,
    },
    margin,
  };

  return (
    <div className={styles.card} style={{ height }}>
      <div className={styles.title}>{title}</div>
      <div className={styles.plotContainer}>
        {responsive ? (
          <ResponsivePlot
            data={data}
            layout={layout}
            config={{
              modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
              displaylogo: false,
              responsive: true,
            }}
          />
        ) : (
          <div style={{ width: "100%", height: "100%" }}>
            <Plotly
              data={data}
              layout={{ ...layout }}
              config={{
                modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
                displaylogo: false,
                responsive: true,
              }}
              useResizeHandler
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
