import SHA256 from "sha.js/sha256";
import seedrandom from "seedrandom";
import dayjs from "dayjs";

export function generateData(value, length, seed) {
  const hash = new SHA256().update(seed).digest("hex");
  const seedValue = parseInt(hash, 16);

  const rng = seedrandom(seedValue.toString());

  const newValues = Array.from({ length: length - 1 }, () => {
    const variation = value * 0.02;
    const newValue = value + (rng() * 2 * variation - variation);
    return Number(newValue.toPrecision(4));
  });

  newValues.push(value);

  return newValues;
}

export function generateBoxWhiskerData({ average, boxSpread, whiskerExtension, n, seed }) {
  if (
    typeof average !== "number" ||
    typeof boxSpread !== "number" ||
    typeof whiskerExtension !== "number" ||
    typeof n !== "number"
  ) {
    throw new Error("All inputs must be numbers");
  }

  const random = seed !== undefined ? seedrandom(seed) : Math.random;

  const q1 = average - boxSpread / 2;
  const q3 = average + boxSpread / 2;
  const iqr = boxSpread;
  const whiskerLower = q1 - whiskerExtension * iqr;
  const whiskerUpper = q3 + whiskerExtension * iqr;

  const dataPoints = [];
  const totalPoints = n;

  for (let i = 0; i < totalPoints; i++) {
    let value;
    const randVal = typeof random === "function" ? random() : Math.random();

    if (randVal < 0.25) {
      value = whiskerLower + (typeof random === "function" ? random() : Math.random()) * (q1 - whiskerLower);
    } else if (randVal < 0.5) {
      value =
        whiskerLower + (q1 - whiskerLower) + (typeof random === "function" ? random() : Math.random()) * (average - q1);
    } else if (randVal < 0.75) {
      value = average + (typeof random === "function" ? random() : Math.random()) * (q3 - average);
    } else {
      value = q3 + (typeof random === "function" ? random() : Math.random()) * (whiskerUpper - q3);
    }

    dataPoints.push(value);
  }

  return dataPoints;
}

export function generateLogarithmicData(minX = 0.5, maxX = 10, minY = 630, maxY = 1200, numPoints = 60) {
  const xValues = [];
  const yValues = [];

  const step = (maxX - minX) / (numPoints - 1);

  for (let i = 0; i < numPoints; i++) {
    const x = minX + i * step;
    xValues.push(x);

    const normalizedLog = Math.log10(x) / Math.log10(maxX);
    const y = minY + (maxY - minY) * normalizedLog;
    yValues.push(y);
  }

  return { xValues, yValues };
}

export function generateRandomString(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);

    result += characters[randomIndex];
  }

  return result;
}

export function generateNoisyLineData({
  minX = 0,
  maxX = 10,
  minY = 0,
  maxY = 100,
  numPoints = 40,
  noiseAmount = 0.2,
  seed = "default",
  datetime = false,
  startDate = dayjs(),
  endDate = dayjs().add(1, "day"),
} = {}) {
  const xValues = [];
  const yValues = [];

  const rng = seedrandom(seed);

  const getXValue = (i, step) => {
    if (datetime) {
      const timeStep = endDate.diff(startDate) / (numPoints - 1);
      return startDate.add(timeStep * i).format("YYYY-MM-DD HH:mm:ss");
    }
    return minX + i * step;
  };

  const step = datetime ? 1 : (maxX - minX) / (numPoints - 1);
  const slope = (maxY - minY) / (numPoints - 1);

  const offsets = Array.from({ length: 4 }, () => ({
    frequency: 1 + rng() * 10,
    phase: rng() * Math.PI * 2,
    amplitude: rng() * 0.5 + 0.5,
  }));

  for (let i = 0; i < numPoints; i++) {
    const x = getXValue(i, step);
    xValues.push(x);

    const baseY = minY + slope * i;

    const noiseInput = datetime ? (i / numPoints) * 10 : x;

    const noise = offsets.reduce(
      (acc, { frequency, phase, amplitude }) => acc + Math.sin(noiseInput * frequency + phase) * amplitude,
      0
    );

    const normalizedNoise = (noise / offsets.length) * noiseAmount * (maxY - minY);

    const y = baseY + normalizedNoise;

    const clampedY = Math.max(minY, Math.min(maxY, y));
    yValues.push(clampedY);
  }

  return { xValues, yValues };
}

export function generateRandomNumber(digits, seed = Math.random().toString()) {
  // eslint-disable-next-line new-cap
  const generator = seedrandom(seed);
  const min = 10 ** (digits - 1);
  const max = 10 ** digits - 1;
  return Math.floor(generator() * (max - min + 1)) + min;
}
