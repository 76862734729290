import ResponsivePlot from "components/ResponsivePlot";
import React from "react";

const data = [
  {
    x: [2, 4, 6, 11, 16, 24, 27, 29],
    y: [6, 7, 11, 15.1, 21, 26, 27.7, 30],
    mode: "markers",
    type: "scatter",
    name: "H354R0",
    marker: {
      color: "#704df4",
      size: 12,
    },
  },
  {
    x: [4, 7, 11.4, 13, 23.5, 26.8],
    y: [12.5, 11.4, 21, 22.4, 22, 26],
    mode: "markers",
    type: "scatter",
    name: "H355R0",
    marker: {
      color: "#25cf9c",
      size: 12,
    },
  },
];

const layout = {
  title: "Crossover At 1 A/Cm2: H354R0 & H355R0 # Start Stop Cycles",
  xaxis: {
    title: "# Start Stop Cycles Completed",
  },
  yaxis: {
    title: "Crossover [H2 in O2, LFL%]",
  },
};

export default function Crossover() {
  return (
    <ResponsivePlot
      data={data}
      layout={layout}
      config={{
        modeBarButtons: [["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d"]],
        displaylogo: false,
        responsive: true,
      }}
    />
  );
}
